import {
	Flex,
	Box,
	Stack,
	Button,
	useToast,
	useDisclosure,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FormUserData } from './subcomponents/FormUserData';

import {
	assingCenterAndPatients,
	createUser,
	updateUser,
} from '../../../services/UserServices';
import { errorToast, okToast } from '../../../utils/toast';
import { checkEmail } from '../../../utils/CheckingData';
import { FormAssingCentersAndPacients } from './subcomponents/FormAssingCentersAndPacients';
import { FormQuestionAddMore } from './subcomponents/FormQuestionAddMore';
import {
	initialCreateUser,
	initialUserCenterLink,
} from '../../../data/initData';
import { RestoreModalUsers } from '../modal/RestoreModalUsers';
import { decodeToken } from '../../../utils/TokenUtils';
import { useSelector } from 'react-redux';

export const FormCreateUser = ({
	onClose,
	isReload,
	setIsReload,
	isUpdateData,
	setIsUpdateData,
	dataUserUpdate,
	setDataUserUpdate,
	step,
	setStep,
	userIdSelect,
	isReloadCentersByUser,
	setIsReloadCentersByUser,
	isReloadPatientsByUser,
	setIsReloadPatientsByUser,
	onCloseStatus,
	setOnCloseStatus,
}) => {
	const {
		isOpen: isOpenRestoreModalUser,
		onOpen: onOpenRestoreModalUser,
		onClose: onCloseRestoreModalUser,
	} = useDisclosure();
	const [inputCreateUser, setCreateUser] = useState(initialCreateUser);
	const [inputLinkCenter, setLinkCenter] = useState(initialUserCenterLink);
	const [selectRadio, setSelectRadio] = useState('all');
	const [userIdRestored, setUserIdRestored] = useState(null);
	const { selectCenter } = useSelector(state => state.selectedCenter);
	const [clickOnSubmit, setClickOnSubmit] = useState(false);
	const toast = useToast();

	useEffect(() => {
		let data;

		if (userIdSelect !== '' && userIdSelect !== null) {
			if (selectCenter?.role === 'admin') {
				data = {
					...inputLinkCenter,
					userId: userIdSelect,
					centerId: selectCenter.centerId,
				};
			} else {
				data = { ...inputLinkCenter, userId: userIdSelect };
			}
		} else if (userIdRestored !== '' && userIdRestored !== null) {
			data = { ...inputLinkCenter, userId: userIdRestored };
		}

		setLinkCenter(data);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userIdSelect, userIdRestored]);

	const userCreated = async () => {
		const userId = await createUser(inputCreateUser, selectCenter);
		if (!userId) return false;

		setLinkCenter({ ...inputLinkCenter, userId });

		return true;
	};

	const userUpdated = async () => {
		const { password, confPassword } = inputCreateUser;

		if (password !== confPassword)
			return errorToast(
				toast,
				'Error en la contraseña',
				'Las contraseñas no coinciden',
			);

		const updatedUser = await updateUser(inputCreateUser);

		if (!updatedUser) {
			return false;
		}

		return true;
	};

	const onSubmit = async () => {
		setClickOnSubmit(true);
		try {
			const { name, lastname, email, password } = inputCreateUser;

			if (
				name.trim() === '' ||
				lastname.trim() === '' ||
				email.trim() === ''
			)
				return errorToast(
					toast,
					'Campos vacios',
					'Por favor rellene todos los campos obligatorios',
				);

			const checkingEmail = checkEmail(email);
			if (!checkingEmail)
				return errorToast(
					toast,
					'Email incorrecto',
					'Por favor inserte un email válido',
				);

			if (!isUpdateData) {
				if (password.trim() === '')
					return errorToast(
						toast,
						'Campos vacios',
						'Por favor inserte una contraseña',
					);
			}

			let register, update;

			if (isUpdateData) {
				update = await userUpdated();
				if (!update)
					return errorToast(toast, 'Error al actualizar usuario');
			} else {
				const { password, confPassword } = inputCreateUser;

				if (password !== confPassword) {
					return errorToast(
						toast,
						'Error en la contraseña',
						'Las contraseñas no coinciden',
					);
				} else {
					delete inputCreateUser.confPassword;
					register = await userCreated();

					if (!register) {
						return errorToast(toast, 'Error al crear usuario');
					}
				}
			}

			if (register) {
				okToast(toast, 'Usuario creado correctamente');
				setStep(2);
			} else if (update) {
				okToast(toast, 'Usuario actualizado correctamente');
				onClose();
			}

			setCreateUser(initialCreateUser);
			setIsReload(!isReload);
		} catch (error) {
			if (isUpdateData) {
				errorToast(toast, 'Error al actualizar usuario');
			} else {
				if (error.message === 'User already exists but is deleted.') {
					let userId = error.data.token;
					userId = decodeToken(userId);
					setUserIdRestored(userId);

					onOpenRestoreModalUser();
				} else if (error.message === 'User already exists.') {
					setCreateUser(prevState => ({
						...prevState,
						password: '',
						confPassword: '',
					}));
					return errorToast(
						toast,
						'Ya existe un usuario registrado con ese email',
					);
				} else {
					errorToast(toast, 'Error al crear usuario', error);
				}
			}
		}
	};

	const assingToUser = async () => {
		try {
			const result = await assingCenterAndPatients(inputLinkCenter);

			if (result) {
				okToast(toast, 'Asignación correcta');
				setIsReloadCentersByUser(!isReloadCentersByUser);
				setIsReloadPatientsByUser(!isReloadPatientsByUser);

				if (!onCloseStatus) {
					setStep(3);
				} else {
					setStep(1);
					onClose();
				}

				setOnCloseStatus(false);
			}
		} catch (error) {
			return errorToast(toast, 'Error al asignar centro/pacientes');
		}
	};

	const onSubmitAssingCenterAndPacients = () => {
		const { userId, centerId, role } = inputLinkCenter;

		if (!userId || !centerId || !role) {
			return errorToast(toast, 'Selecciona centro y rol');
		}

		assingToUser();
	};

	const resetInputCreateUser = () => {
		setCreateUser(initialCreateUser);
	};

	return (
		<>
			<Flex align={'center'} justify={'center'}>
				<Stack spacing={8} mx={'auto'} maxW={'lg'}>
					<Box rounded={'lg'} bg={'white'} p={3}>
						{step === 1 ? (
							<FormUserData
								inputCreateUser={inputCreateUser}
								setCreateUser={setCreateUser}
								dataUserUpdate={dataUserUpdate}
								isUpdateData={isUpdateData}
								clickOnSubmit={clickOnSubmit}
							/>
						) : step === 2 ? (
							<FormAssingCentersAndPacients
								inputLinkCenter={inputLinkCenter}
								setLinkCenter={setLinkCenter}
								selectRadio={selectRadio}
								setSelectRadio={setSelectRadio}
							/>
						) : (
							step === 3 && <FormQuestionAddMore />
						)}
						<Flex
							w='100%'
							justifyContent={
								step === 1 ? 'right' : 'space-between'
							}
							gap={4}
						>
							{userIdSelect && isUpdateData && (
								<Button
									size='lg'
									w={'100%'}
									bg={'red'}
									color={'white'}
									transition={'0.5s'}
									_hover={{
										bg: 'gray.600',
										color: 'white',
									}}
									onClick={() => {
										isUpdateData && setIsUpdateData(false);
										setDataUserUpdate({});
										onClose();
									}}
								>
									Cancelar
								</Button>
							)}
							{step === 1 && !isUpdateData ? (
								<Button
									loadingText='Submitting'
									size='lg'
									w={'100%'}
									bg={'teal300'}
									transition={'0.5s'}
									_hover={{
										bg: 'gray.600',
										color: 'white',
									}}
									color={'white'}
									onClick={() => onSubmit()}
								>
									Siguiente
								</Button>
							) : step === 1 && isUpdateData ? (
								<>
									<Button
										loadingText='Submitting'
										size='lg'
										w={'100%'}
										bg={'teal300'}
										transition={'0.5s'}
										_hover={{
											bg: 'gray.600',
											color: 'white',
										}}
										color={'white'}
										onClick={() => onSubmit()}
									>
										Actualizar
									</Button>
								</>
							) : step === 2 ? (
								<>
									<Button
										loadingText='Submitting'
										size='lg'
										w={'100%'}
										bg={'teal300'}
										color={'white'}
										transition={'0.5s'}
										_hover={{
											bg: 'gray.600',
											color: 'white',
										}}
										onClick={() => {
											onSubmitAssingCenterAndPacients();
											setSelectRadio('all');
										}}
										isDisabled={
											inputLinkCenter.centerId === '' ||
											inputLinkCenter.role === ''
										}
									>
										Asignar centro
									</Button>
								</>
							) : (
								step === 3 && (
									<>
										<Button
											loadingText='Submitting'
											size='lg'
											w={'100%'}
											bg={'teal300'}
											color={'white'}
											transition={'0.5s'}
											_hover={{
												bg: 'gray.600',
												color: 'white',
											}}
											onClick={() => {
												setLinkCenter({
													...inputLinkCenter,
													centerId: '',
													role: '',
													patients: [],
												});
												setStep(2);
											}}
										>
											Asignar otro centro
										</Button>
										<Button
											loadingText='Submitting'
											size='lg'
											w={'80%'}
											bg={'teal300'}
											color={'white'}
											transition={'0.5s'}
											_hover={{
												bg: 'gray.600',
												color: 'white',
											}}
											onClick={() => {
												onClose();
												setStep(1);
											}}
										>
											Cerrar
										</Button>
									</>
								)
							)}
						</Flex>
					</Box>
				</Stack>
			</Flex>
			{isOpenRestoreModalUser && (
				<RestoreModalUsers
					isOpen={isOpenRestoreModalUser}
					onCloseRestoreModalUser={onCloseRestoreModalUser}
					onCloseFormCreateUserModal={onClose}
					inputCreateUser={inputCreateUser}
					userIdRestored={userIdRestored}
					isReload={isReload}
					setIsReload={setIsReload}
					setStep={setStep}
					resetInputCreateUser={resetInputCreateUser}
				/>
			)}
		</>
	);
};
